import { useState } from "react";
import "../css/NavBar.scss";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light d-flex align-items-center pt-4">
        <a className="navbar-brand" href="#">
          <a href="/">
            <img src="/studio56.svg" alt="" width="160" height="auto"></img>
          </a>
        </a>
      </nav>
    </>
  );
}

export default NavBar;
