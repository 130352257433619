import React from "react";
import "../../css/FAQ.scss";

function FAQ() {
  return (
    <div className="faq-container container-fluid">
      <div className="d-flex justify-content-center flex-column align-items-center">
        <h1 className="font-weight-bold text-center my-5">
          Preguntas frecuentes
        </h1>
      </div>
      <div className="container pt-3">
        <h3 className="font-weight-bold">¿Cuánto cuesta el servicio?</h3>
        <p className="pt-4">
          El precio se ajusta a la medida de tus necesidades y economía. Haz
          clic{" "}
          <span className="blue-dark text-color-black font-weight-bold pointer">
            aquí
          </span>{" "}
          para continuar con el proceso y obtener una cotización.
        </p>
      </div>
      <div className="container pt-5">
        <h3 className="font-weight-bold">
          ¿Puedo pagar con tarjeta de crédito?
        </h3>
        <p className="pt-4">
          Por ahora, los pagos son a través de transferencia bancaria, pero más
          adelante contaremos con sistema de pago con tarjeta.
        </p>
      </div>
      <div className="container pt-5">
        <h3 className="font-weight-bold">
          ¿Puedo contratar algún servicio de manera individual?
        </h3>
        <p className="pt-4">
          Sí es posible. Consulta con alguno de nuestros asesores acerca de los
          paquetes que tenemos disponibles.
        </p>
      </div>
      <div className="options container pt-4">
        <ul className="list-unstyled">
          <li>
            <span>&#10132;</span> Producción audiosivual
          </li>
          <li>
            <span>&#10132;</span> Estudio de grabación
          </li>
          <li>
            <span>&#10132;</span> Consultoría pedagógica
          </li>
          <li>
            <span>&#10132;</span> Consulta de nuestros aliados
          </li>
        </ul>
      </div>
    </div>
  );
}

export default FAQ;
