import React from "react";
import "../../css/Help.scss";
import { openWhatsApp } from "../../js/WhatsAppService";
import FooterC from "./Footer";

function Footer() {
  return (
    <div className="help w-100 py-5">
      <div className="content d-flex flex-column align-items-center w-100 justify-content-center py-5">
        <div className="w-75 d-flex justify-content-center">
          <h3 className="fw-bold text-center">
            No cuesta nada, nosotros te apoyamos con tus dudas
          </h3>
        </div>
        <button
          onClick={openWhatsApp}
          type="button"
          className="btn p-3 mt-5 fw-bold"
        >
          SOLICITAR COTIZACION
        </button>
      </div>
      <FooterC />
    </div>
  );
}

export default Footer;
