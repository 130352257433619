import React, { CSSProperties } from "react";
import "../../css/ContentSecondSection.scss";
import { openWhatsApp } from "../../js/WhatsAppService";

function SecondSection() {
  const aspectRatioStyle = {
    "--bs-aspect-ratio": "50%",
  } as React.CSSProperties;

  return (
    <div className="content-second-section-container container-fluid d-flex justify-content-center flex-column align-items-center">
      <div className="d-flex justify-content-center flex-column align-items-center">
        <h3 className="text-center mb-5">
          Lleva la capacitación al siguiente nivel de profesionalismo y
          automatización tecnológica
        </h3>
        <div className="ratio" style={aspectRatioStyle}>
          <iframe
            src="https://s3.amazonaws.com/studio56.com/Video+Landing+Studio56+Oficial.mp4"
            title="YouTube video"
          ></iframe>
        </div>
        <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
          <h3 className="text-center">
            No cuesta nada, nosotros te apoyamos con tus dudas
          </h3>
          <button
            onClick={openWhatsApp}
            type="button"
            className="btn px-5 py-3 my-5 fw-bold"
          >
            CONTACTAR CON UN ASESOR
          </button>
        </div>
      </div>
    </div>
  );
}

export default SecondSection;
