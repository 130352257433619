export function openWhatsApp() {
    const phoneNumber = '+5215561438501'; // Change this to the phone number you want to message
    const message = encodeURIComponent('¡Hola! 👋 Somos Studio56, especialistas en producción. ¿Necesitas ayuda para tu proyecto? Contáctanos aquí y te ayudaremos a hacerlo realidad. 😊🎬');

    var url = "https://api.whatsapp.com/send/?phone=" + phoneNumber + "&text=" + message;

    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
}