import React from "react";
import "../../css/IncludedSection.scss";
import { openWhatsApp } from "../../js/WhatsAppService";

function Steps() {
  return (
    <div className="include-second-container d-flex justify-content-center flex-column align-items-center">
      <h1 className="fw-bold my-5">¿Qué incluye?</h1>
      <ul className="list-unstyled">
        <li className="d-flex align-items-center pb-3">
          <img src="/ok.svg" alt="" width="20" className="me-3"></img>
          <span className="pl-3">Diseño instruccional pedagógico</span>
        </li>
        <li className="d-flex align-items-center py-3">
          <img src="/ok.svg" alt="" width="20" className="me-3"></img>
          <span className="pl-3">Producción audiovisual</span>
        </li>
        <li className="d-flex align-items-center py-3">
          <img src="/ok.svg" alt="" width="20" className="me-3"></img>
          <span className="pl-3">Estudio de grabación</span>
        </li>
        <li className="d-flex align-items-center py-3">
          <img src="/ok.svg" alt="" width="20" className="me-3"></img>
          <span className="pl-3">Consultoría y metodologías</span>
        </li>
        <li className="d-flex align-items-center py-3">
          <img src="/ok.svg" alt="" width="20" className="me-3"></img>
          <span className="pl-3">Acompañamiento</span>
        </li>
      </ul>
      <h2 className="pt-3 fw-bold text-center">
        Si aún tienes dudas contáctanos
      </h2>
      <button
        onClick={openWhatsApp}
        type="button"
        className="btn p-3 my-5 fw-bold"
      >
        CONTACTAR AL AREA DE VENTAS
      </button>
    </div>
  );
}

export default Steps;
