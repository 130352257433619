import React from "react";
import "../../css/FourSection.scss";

function FourSection() {
  return (
    <div className="benefits-container container-fluid">
      <div className="d-flex justify-content-center flex-column align-items-center">
        <h1 className="font-weight-bold text-center my-5">Más beneficios</h1>
        <div className="container pt-3">
          <div className="row">
            <div className="col-lg-4">
              <h3 className="font-weight-bold">Gestiona a tu alumnado</h3>
              <p className="pt-3">
                Lleva un control de tus alúmnos y capacita fácilmente.
              </p>
            </div>
            <div className="col-lg-4">
              <h3 className="font-weight-bold">Métricas de progreso</h3>
              <p className="pt-3">
                Nuestra plataforma te permite conocer el progreso en tiempo real
                de tus alumnos.
              </p>
            </div>
            <div className="col-lg-4">
              <h3 className="font-weight-bold">Capacitación integral</h3>
              <p className="pt-3">
                Da acceso a tu personal sin importar la cantidad.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <h3 className="font-weight-bold">Incentivos </h3>
              <p className="pt-3">
                Motiva a tu personal con nuestras campañas de incentivos.
              </p>
            </div>
            <div className="col-lg-4">
              <h3 className="font-weight-bold">Capacitación flash</h3>
              <p className="pt-3">
                Gracias a nuestras metodologías logra que tus alumnos se
                capaciten en tiempo record.
              </p>
            </div>
            <div className="col-lg-4">
              <h3 className="font-weight-bold">Agenda de consultores </h3>
              <p className="pt-3">
                Obtén beneficios exclusivos con nuestros consultores aliados de
                gran prestigio.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FourSection;
