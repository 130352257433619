import React from "react";
import "../../css/MainImage.scss";
import { openWhatsApp } from "../../js/WhatsAppService";

function MainImage() {
  return (
    <div
      className="main-image-container container d-flex justify-content-center align-items-center flex-column"
      style={{ marginTop: "110px", marginBottom: "190px" }}
    >
      <img src="/igly-prod-main.svg" alt="" />
      <h1 className="mt-5 fw-bold">Crea tu universidad online inteligente</h1>
      <h2 className="mt-4">
        Capacita inteligentemente y eleva los resultados de tu empresa
      </h2>
      <button
        onClick={openWhatsApp}
        type="button"
        className="btn px-5 py-3  mt-5 fw-bold"
      >
        SOLICITAR COTIZACIÓN
      </button>
    </div>
  );
}

export default MainImage;
