import React from "react";
import "./App.css";
import NavBar from "./components/NavBar";
import MainImage from "./components/content/MainImage";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import SecondSection from "./components/content/SecondSection";
import ThirdSection from "./components/content/ThirdSection";
import FourSection from "./components/content/FourSection";
import IncludedSection from "./components/content/IncludedSection";
import ZtreamersBannerSection from "./components/content/ZtreamersBannerSection";
import FAQ from "./components/content/FAQ";
import Help from "./components/content/Help";

function App() {
  return (
    <div className="container-fluid px-0 d-flex justify-content-center align-items-center flex-column">
      <NavBar />
      <MainImage />
      <SecondSection />
      <ThirdSection />
      <IncludedSection />
      <ZtreamersBannerSection />
      <FourSection />
      <FAQ />
      <Help />
    </div>
  );
}

export default App;
