import React from "react";
import "../../css/ContentThirdSection.scss";

function ThirdSection() {
  return (
    <div className="third-content-section container d-flex justify-content-center flex-column align-items-center">
      <h1 className="my-5 fw-bold">
        En tan solo <span>4 pasos</span>
      </h1>
      <div className="row">
        <div className="col-md d-flex flex-column align-items-center px-5">
          <img src="/step-1.svg" alt="" width="90" height="90"></img>
          <p className="text-center pt-4 px-2">
            Identifica tus áreas de oportunidad y elije a tus capacitadores
            internos o a nuestros consultores aliados.
          </p>
        </div>
        <div className="col-md d-flex flex-column align-items-center px-5">
          <img src="/step-2.svg" alt="" width="90" height="90"></img>
          <p className="text-center pt-4 px-2">
            Te ayudamos con el diseño instruccional pedagógico con nuestros
            expertos
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md d-flex flex-column align-items-center px-5">
          <img src="/step-3.svg" alt="" width="90" height="90"></img>
          <p className="text-center pt-4 px-2">
            Producimos tus cursos en tiempo record con nuestra tecnología y
            metodologías de producción.
          </p>
        </div>
        <div className="col-md d-flex flex-column align-items-center px-5">
          <img src="/step-4.svg" alt="" width="90" height="90"></img>
          <p className="text-center pt-4 px-2">
            Monta tus cursos en tu plataforma y pon tu universidad online
            inteligente en acción.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ThirdSection;
