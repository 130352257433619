import React from "react";
import "../../css/Ztreamers.scss";

const ZtreamersBannerSection = () => {
  return (
    <div className="ztreamer-container container-fluid d-flex justify-content-center align-items-center">
      <div className="info-container d-flex justify-content-center align-items-center">
        <div className="ztreamers-info-container">
          <img
            data-v-390680fc=""
            id="streamers-banner"
            src="/ztreamers-logo-white.svg"
            width="220"
            height="auto"
          ></img>
          <h2 className="mt-5 fw-bold text-center">
            Genera tu área de capacitación digital permanente
          </h2>
          <p className="pt-4 text-center">
            Olvida las salas de capacitación físicas y obtén acceso a la
            plataforma Ztreamers en donde podrás montar tus capacitaciones
            online, realizar streaming en vivo, y mucho más desde un solo lugar.
          </p>
          <button type="button" className="btn px-5 py-3  mt-5 fw-bold">
            MAS INFORMACIÓN
          </button>
        </div>
        <div className="img-container"></div>
      </div>
    </div>
  );
};

export default ZtreamersBannerSection;
