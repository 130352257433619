import React from "react";
import "../../css/Footer.scss";

function Footer() {
  return (
    <div id="footer">
      <div className="footer d-flex justify-content-center p-5 bg-white-dark-5 flex-column align-items-center">
        <a href="/">
          <img
            id="iglyLogoHomeWhite"
            src="/studio56.svg"
            width="170"
            height="auto"
            alt=""
          ></img>
        </a>
        <div className="all-rights-reserved d-flex flex-column justify-content-center align-items-center pt-5">
          <span className="text-size-5 py-3 text-center">
            © 2023 Todos los derechos reservados.
          </span>
          <span className="text-color-white-dark-2 font-weight-bold text-size-5 pl-1 text-center">
            <a
              href="https://igly.mx"
              className="text-reset text-decoration-none"
            >
              Powered by <span className="it">Igly Technologies</span>
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
